import Label from '@/components/atoms/Label'
import {LinkType} from '@/components/types/link'

type LabelProps = {
  block: {
    title: string
    link: LinkType
    isSmall: boolean
  }
}

export default function Intro({block}: LabelProps) {
  return <Label {...block} />
}
